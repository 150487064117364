import React from 'react'
import { LayoutIR } from '../../components/organisms/Layout/LayoutIR'
import { useLoadLocalScript } from '../../components/hooks/useLoadLocalScript'
import {
  selectMenuStyle,
  styleIRNews,
} from '../../components/styles/styleIRNews'
import { css } from '@emotion/react'
import { SEOIR } from '../../components/data/SEO'
import { ENGLISH_TITLE } from '../../components/data/English'

/**
 * ページコンポーネント
 */
/** */
const Page = () => {
  // ローカルのscriptタグを埋めたい場合
  useLoadLocalScript('/common/js/ir/XjStorageLoaderIRNews.js')
  return (
    <LayoutIR
      heading="IRニュース"
      headingEnglish={ENGLISH_TITLE.IRNews}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: 'IRニュース', url: '' },
      ]}
    >
      <div className="pc:px-8 pc:pb-12 pc:pt-4 bg-white">
        {/* プルダウンここから */}
        <div
          css={css`
            ${selectMenuStyle}
            width: 212px;
          `}
          className="mt-8"
        >
          <form action="#" method="post" name="form" id="form">
            <select
              name="year"
              id="xj-select-year_s"
              className="contact-select ir-select"
            ></select>
          </form>
        </div>
        {/* プルダウンここまで */}
        {/* 埋め込みエリア */}
        <div css={styleIRNews}>
          <div id="xj-select-category"></div>
          <div id="xj-mainlist"></div>
        </div>
        {/* 埋め込みエリアここまで */}
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEOIR title="IRニュース" url="/ir/news" />
